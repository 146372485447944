import React, {useEffect, useState} from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
const Body = () => {

    const [FAQS, setFAQS] = useState([]),

    faqsGET = async () => {
        try {
            axios.get(`${window.location.origin}/api/faqs`)
            .then((ret)=>{
                setFAQS(ret.data)
                console.log(ret.data)
            })
            .catch((err)=>{console.log(err)})
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(()=>{
        faqsGET()
    }, [setFAQS])

    return(
        <div className="row g-2">
            <div className="col-12 order-1">
                <span className="Flora-Font Sacrd-Grn text-uppercase fs-3">web3 101</span>
            </div>
            <div className="col-12 order-2">
                <span className="Flora-Font Sacrd-Grn text-uppercase fs-3">About SACRΞD GARDΞN</span>
            </div>
            {
                FAQS.map((ret)=>{
                    return(
                        <div id="faq" className={ret.category.split(" ")[0]==="About"? "col-12 d-flex flex-column rounded order-2" : "col-12 d-flex flex-column rounded order-1"} 
                        key={FAQS.indexOf(ret)} style={{backgroundColor: "#FFF5B5", border: "solid 3px #FFE0E0"}}>
                            <div className="d-flex flex-row align-items-center ">
                                <span className='Flora-Font fs-5 Sacrd-Grn'>{ret.faq.quest}</span>
                                <button className="btn ms-auto fs-5 Sacrd-Grn" type="button" data-bs-toggle="collapse" data-bs-target={`#faq${FAQS.indexOf(ret)}`} aria-expanded="false" aria-controls={`faq${FAQS.indexOf(ret)}`}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </button>
                            </div>
                            <div className="collapse p-1 Sacrd-Grn" id={`faq${FAQS.indexOf(ret)}`}>
                                <div className="Flora-Font card card-body">
                                    <span>
                                        {ret.faq.answ}
                                    </span>
                                    {
                                        ret.links?
                                        <div className="d-flex flex-column rounded mt-2">
                                            <div className="p-1 rounded" style={{backgroundColor: "#00544B"}}></div>
                                            <span>Resources:</span>
                                            <div className="d-flex flex-row">
                                                {
                                                    ret.links.map((res)=>{
                                                        return(
                                                            <a className="m-1" key={ret.links.indexOf(res)} href={res.src}>
                                                                <span className="Flora-Font">{res.name}</span>
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Body