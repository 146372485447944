import React, {useState, useEffect} from "react";

import logo from "../../images/header/full_logo.png"


const Header = () => {
    const [header, setHeader] = useState("hide")
    useEffect(()=>{
        window.addEventListener("scroll", ()=>{
            if (window.scrollY >= 175){
                setHeader("show")
            } else {
                setHeader("hide")
            }
        })
    }, [header])
    return(
        <div className="row">
            <div className="col-12 d-flex flex-column" style={{backgroundColor: "#FFE0E0"}}>
                <div className="d-flex flex-row py-2 shadow" style={{width: "100vw", position: "relative", left: "-12px", zIndex:1}}>
                    <a href="/"><img className="img-fluid" src={logo} style={{height: "50px"}} alt="Sacrd Gardn Logo"/></a>
                    <a className="btn ms-auto Sacrd-Grn" href="/faqs">
                        <i className="fa-regular fa-circle-question"></i>
                    </a> 
                </div>
                <div className="d-flex flex-row flex-wrap Flora-Font p-1" style={{backgroundColor: "#FFAC80", width: "100vw", position: "relative", left: "-12px"}}>
                    <div className="d-flex flex-row justify-content-evenly">
                        <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="/">home</a>
                        <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="/team">team</a>
                        {/* <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="/partners">partners</a> */}
                        <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="https://shop.sacrdgardn.com">shop</a>
                        <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="/library">library</a>
                    </div>
                    {/* <div className="d-flex flex-row ms-auto">
                        <button className="btn">
                            <i className="fa-regular fa-user Sacrd-Grn"></i>                
                        </button>
                    </div> */}
                </div>
            </div>
            <div className="col-12" id="header" style={header === "hide" ? {backgroundColor: "#FFAC80", width: "101vw", position: "fixed", top: "-50px", zIndex: 99, transition: "all ease 0.5s"} : {backgroundColor: "#FFAC80", width: "101vw", position: "fixed", top: "0px", zIndex: 99, transition: "all ease 0.5s"}}>
                <div className="d-flex flex-row flex-wrap Flora-Font p-1" style={{}}>
                    <div className="d-flex flex-row justify-content-evenly">
                        <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="/">home</a>
                        <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="/team">team</a>
                        {/* <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="/partners">partners</a> */}
                        <a className="btn Sacrd-Grn Flora-Font text-uppercase" href="https://shop.sacrdgardn.com">shop</a>
                    </div>
                    {/* <div className="d-flex flex-row ms-auto">
                        <button className="btn">
                            <i className="fa-regular fa-user Sacrd-Grn"></i>                
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Header