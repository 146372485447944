import React from "react";

import Header from "../components/header";
import Body from "./components/body";
import Footer from "../components/footer";

const Botanical = () => {

    return(
        <div className="container-fluid" style={{overflowX: "clip"}}>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    )
}

export default Botanical