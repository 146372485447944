import React, {useState, useEffect} from "react"

import meo from "../../../images/library/compounds/5meo_DMTRender_1.png"
import dmt from "../../../images/library/compounds/DMT_render.png"
import maoi from "../../../images/library/compounds/MAOI_render.png"
import mescaline from "../../../images/library/compounds/mescaline_molecule.png"
import nicotine from "../../../images/library/compounds/Nicotine_render.png"
import thc from "../../../images/library/compounds/thc_render.png"

import aya_stat_2 from "../../../images/library/ayahuasca/aya_stat_2.jpeg"
import aya_cover from "../../../images/library/ayahuasca/aya_cover.png"

import blue_stat_1 from "../../../images/library/blue_lotus/blue_stat_1.jpeg"
import blue_cover from "../../../images/library/blue_lotus/blue_cover.png"

import brug_stat_1 from "../../../images/library/brugmansia/brug_stat_1.jpeg"
import brug_cover from "../../../images/library/brugmansia/brug_cover.png"

import can_stat_2 from "../../../images/library/cannabis/can_stat_2.jpeg"
import can_cover from "../../../images/library/cannabis/can_cover.png"

import chac_stat_2 from "../../../images/library/chacruna/chac_stat_2.jpeg"
import chac_cover from "../../../images/library/chacruna/chac_cover.png"

import dat_stat_2 from "../../../images/library/datura/dat_stat_2.jpeg"
import dat_cover from "../../../images/library/datura/dat_cover.png"

import hua_stat_1 from "../../../images/library/huachuma/hua_stat_1.jpeg"
import hua_cover from "../../../images/library/huachuma/hua_cover.png"

import ice_stat_1 from "../../../images/library/iceplant/ice_stat_1.jpeg"
import ice_cover from "../../../images/library/iceplant/ice_cover.png"

import kan_stat_2 from "../../../images/library/kanna/kan_stat_2.jpeg"
import kan_cover from "../../../images/library/kanna/kan_cover.png"

import krat_stat_1 from "../../../images/library/kratom/krat_stat_1.jpeg"
import krat_cover from "../../../images/library/kratom/krat_cover.png"

import mor_stat_1 from "../../../images/library/morning_glory/mor_stat_1.jpeg"
import mor_cover from "../../../images/library/morning_glory/mor_cover.png"

import nic_stat_2 from "../../../images/library/nicotiana/nic_stat_2.jpeg"
import nic_cover from "../../../images/library/nicotiana/nic_cover.png"

import pass_stat_3 from "../../../images/library/passion_flower/pass_stat_3.jpeg"
import pass_cover from "../../../images/library/passion_flower/pass_cover.png"

import pey_stat_1 from "../../../images/library/peyote/pey_stat_1.jpeg"
import pey_cover from "../../../images/library/peyote/pey_cover.png"

import pop_stat_3 from "../../../images/library/poppy/pop_stat_3.jpeg"
import pop_cover from "../../../images/library/poppy/pop_cover.png"

import sal_stat_1 from "../../../images/library/salvia/sal_stat_1.jpeg"
import sal_cover from "../../../images/library/salvia/sal_cover.png"

import tri_stat_1 from "../../../images/library/trichocereus/tri_stat_1.jpeg"
import tri_cover from "../../../images/library/trichocereus/tri_cover.png"

const Body = () => {
    const [indx, setIndx] = useState(0),
    [catalog, setCatalog] = useState("hide"),
    [statlog, setStatlog] = useState("closed"),
    data = [
        {
            shelf: {
                title: "Ayahuasca",
                cover: aya_cover
            },
            book: {
                sceintific_name: "Banisteriopsis caapi",
                common_name: "Ayahuasca",
                native_to: "Currently throughout the Amazon Basin, but not certain where it originated.",
                medicinal_parts: "vine",
                big_sky: "Deep Forest",
                botanical_characteristics: "Flowering only in the tropics and bearing pink and white flower, ayahuasca is a vine with a woody stem and large green leaves. Its fruit resembles that of a maple.",
                botanical_care: "Grown almost always via cutting. Enjoys moist, humus rich soil and a tropical environment.",
                psychedelic_compounds: "MAOI beta carbolines",
                part_for_consumption:"The entire plant is consumed. The leaves and bark can be dried and smoked or made into a powder. The plant is usually mixed with an additive to create psychedelic properties. Most commonly chacruna.",
                effects_of_consumption:"Ayahuasca acts as an MAOI inhibitor which allows chemicals like DMT to enter the brain. If used alone, the plant produces sedative effects.",
                indigenous_roots: "The ayahuasca vine was used in ritual by the Colombian Tukano tribe Desana. The ritual would last for 8-10 hours and include singing and dancing, however only men would consume.",
                traditional_and_medicinal_uses: "Used to treat a wide variety of diseases in some areas of the Amazon. Also used topically.",
                legal_status_in_US: "Legal to grow.",
                plant_social_media: "Banisteriopsis caapi, latin for Ayahuasca vine or b. caapi, is a giant vine with flowers that infrequently blossom. It is native to South America. The vine can grow up to 100 ft in length, twining on other plants for support. It is an entheogenic plant that makes up half of an ayahuasca brew and has been used for thousands of years as a plant teacher in the Amazon rain forest communities.",
                images: {
                    compounds: maoi,
                    static: [aya_stat_2],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/ayahuasca/aya_ani_1.mp4", "https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/ayahuasca/aya_ani_2.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Poppy",
                cover: pop_cover
            },
            book: {
                sceintific_name: "Papaver somniferum",
                common_name: "Poppy",
                native_to: "Central and Southern Europe",
                medicinal_parts: "fruit bulbs, leaves and seeds",
                big_sky: "Not considered an entheogen",
                botanical_characteristics: "Distinct taproot with blue/gray or light green leaves. The long flower stalk bears a single flower with four petals. The fruit capsule is smooth and round and varies from 2-6 cm.",
                botanical_care: "Easily propagated from seed with a germination time of 10-15 days. Poppy seedlings do not like to be transplanted. They thrive in warm, lime rich soils.",
                psychedelic_compounds: "Morphine",
                part_for_consumption:"The leaves can be dried and smoked. The fruit bulbs can also be dried or scored to release the milky substance inside, which when dried is raw opium. The seeds can also be eaten.",
                effects_of_consumption:"Soothing of mood, sleepiness, relaxation, increased sexual desire.",
                indigenous_roots: 'Earliest mention of poppy was on a Sumerian tablet around 3000 BC, where it is described as the "plant of happiness".',
                traditional_and_medicinal_uses: "Sleep promoting effects, as well as mood elevation and a whole host of other illnesses: cough, diarrhea, and inflammation.",
                legal_status_in_US: "Legal to grow the plant, but not harvest the opium.",
                plant_social_media: 'Papaver somniferum, latin for the opium bearing Poppy is a luscious, flowering plant with a four petaled flower and bulb fruit that contains opium. Native to Europe and dating back to 3000 B.C.E., poppies have been used for a variety of medical conditions across the world. It was earliest described as the "plant of happiness".',
                images: {
                    compounds: null,
                    static: [pop_stat_3],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/poppy/pop_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Cannabis",
                cover: can_cover
            },
            book: {
                sceintific_name: "Cannabis (indica, sativa, ruderalis)",
                common_name: "Cannabis",
                native_to: "Northern India, Afghanistan, Pakistan and the Himalayas",
                medicinal_parts: "flower",
                big_sky: "Infinite Other",
                botanical_characteristics: "Height depends on the genus. It's many branches can yield a Christmas Tree appearance. It is an annual plant that can be of male or female sex. Females produce bulbous flowers that are used for medicine.",
                botanical_care: "Can be grown from seeds or cuttings. Growth cycles are determined by length of day. As days get shorter, the plant flowers and then dies. Prefers hot, dry climates with cool nights. They must be watered regularly but do not like to keep their feet wet.",
                psychedelic_compounds: "THC and its derivatives (THCV, etc)",
                part_for_consumption: "Almost the entire cannabis plant can be consumed. The flowers are dried and either smoked, eaten or used to create an extract oil or salve. Leaves can be juiced and seeds can eaten. The cannabis plant's constituents are high in fiber, healthy fats and anti-oxidants.",
                effects_of_consumption: "Euphoria, pain relief, reduced anxiety, sleepiness, relaxation and sexual arousal.",
                indigenous_roots: 'Use as a medicine in India has been documented as far back as 1400 B.C.E and was used in prehistoric times by shamans in Northern India and the Himalayas.',
                traditional_and_medicinal_uses: "Early medicinal uses included stomach issues, difficult births, tremors, depression, and pain.",
                legal_status_in_US: "Only cannabis containing <.3% THC is federally legal. Most states have a program that allows access to medical cannabis and 20 states allow any adult 21+ to possess cannabis legally. ",
                plant_social_media: 'Cannabis is one of the oldest cultvated plants. An annual that has both male and female sexes, the cannabis plant has been used as a medicine as far back as 1400 B.C.E. Nearly all parts of the plant have medicinal/nutritional value including the leaves, seeds and flowers. Cannabis has been used across the globe in connection with spiritual, physical and psychological healing and only in modern history was considered a dangerous plant.',
                images: {
                    compounds: thc,
                    static: [can_stat_2],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/cannabis/can_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Salvia",
                cover: sal_cover
            },
            book: {
                sceintific_name: "Salvia divinorum ",
                common_name: "Salvia",
                native_to: "Mazatec region of the Sierra Madre Oriental in the Mexican state of Oaxaca.",
                medicinal_parts: "leaves",
                big_sky: "Infinite Other",
                botanical_characteristics: "A perennial evergreen plant with a square-like stem. The leaves are light and dark green and covered in fine hairs. It's white flowers usually bloom in January. Fruiting in a cultivated plant is rare.",
                botanical_care: "Propagated from cutting. The plant likes high humdity and requires a lot of water. It prefers shade and dark soil.",
                psychedelic_compounds: "Salvinorin A",
                part_for_consumption: "The leaves, which can be chewed like tobacco if they are fresh and smoked if they are dried. It can also be consumed as a tincture.",
                effects_of_consumption: "Hallucinations that can include becoming an object, revisiting scenes from childhood, out of body experiences and movement sensations like twisting and pulling of the body.",
                indigenous_roots: 'Some believe first use was by the Aztecs. ',
                traditional_and_medicinal_uses: "Used in ritual, sometimes as a replacement for psilocybin. Medicinal uses included headaches, rheumatism and anemia.",
                legal_status_in_US: "Salvia is legal to grow in the following states: AK, AZ, CA, DC, ID, MA, MT, NV, NM, NJ, NY, OR, SC, UT, VT, VA, WA.",
                plant_social_media: "Salvia is powerful, and one of the rarest of all plant entheogens. Native to Mexico, it's intense hallucinatory powers have made a part of shamanic ritual of the Mazatec people of Oaxaca. An evergreen annual plant, it's leaves can be consumed dried or fresh and the effects can include out of body experiences and overlapping realities.",
                images: {
                    compounds: null,
                    static: [sal_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/salvia/sal_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Huachuma",
                cover: tri_cover
            },
            book: {
                sceintific_name: "Trichocereus pachanoi",
                common_name: "Huachuma",
                native_to: "Peru at elevations between 2000-3000 meters",
                medicinal_parts: "cactus",
                big_sky: "Big Sky",
                botanical_characteristics: "Tall and nearly spinless, it can grow up to 6 meters. It has white flowers which only bloom at night and can have anywhere from 0-12 ribs.",
                botanical_care: "Can be propagated from seeds or cuttings. It loves the climate in California and grows quickly when watered regularly, but can also survive well without water. It can also be grafted onto other cacti.",
                psychedelic_compounds: "Mescaline",
                part_for_consumption: "Slices of the fresh cactus and also the rind, ground up into a powder.",
                effects_of_consumption: "When consumed alone, effects vary based on dose. Higher doses can yield psychedelic effects, more on the emotional than sensory level. The plant is often mixed with other entheogens like Brugmansia and Datura to intensify the psychoactive effect.",
                indigenous_roots: 'Shamans in early Andean civilization have used the cactus for at least 2000 years.',
                traditional_and_medicinal_uses: "Played a role in shamanic rituals for the shaman to consume to know what was wrong with the people they were healing. ",
                legal_status_in_US: "Legal to grow the plant, but not harvest the mescaline.",
                plant_social_media: "Huachuma originated in Peru and was a tool used by shamans to identify the problems in those they were trying to heal. A hardy, near spineless cactus, Huachuma can grow to be 6 meters and only flowers at night. The flesh can be consumed and at high doses brings about a psychedelic experience described as being emotional rather than sensory.",
                images: {
                    compounds: mescaline,
                    static: [tri_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/trichocereus/tri_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Peyote",
                cover: pey_cover
            },
            book: {
                sceintific_name: "Lophophora williamsii",
                common_name: "Peyote",
                native_to: "Desert areas of Texas to central Mexico",
                medicinal_parts: "cactus ",
                big_sky: "Big Sky",
                botanical_characteristics: "Bearing light pink flowers and black seeds, peyote cacti are thornless and round and can have ribs that vary in number and structure,",
                botanical_care: "Propagated from seed, the cactus prefers sandy, clay soil with a lot of nutrients. The seeds are small and may take several weeks to germinate. Although it does not tolerate frost, it can survive the winter with no water. It takes about 5 years of growth before the cactus can be harvested.",
                psychedelic_compounds: "Mescaline",
                part_for_consumption: 'The "buttons" or head of the cacti, which can be dried and ground into powder or eaten fresh.',
                effects_of_consumption: "Similarly to psilocybin and LSD, the effects of peyote are hallucinogenic and psychedelic in nature. The effects are often preceded by nausea and vomiting and can last 6-9 hours depending on the dose.",
                indigenous_roots: 'Archeologists have found evidence of peyote buttons in Texas dating back 6000 years. The Mescalero Apache are credited with bringing the ritual use of peyote into North America.',
                traditional_and_medicinal_uses: "Prehistoric shamanic use among a variety of tribes in what are now Mexico and Texas. It's ritual use has been kept alive by the Huichol and Tarahumara cultures.",
                legal_status_in_US: "Members of the Native American Church and various other approved Native American groups are allowed to use peyote for ceremonial purposes. It remains otherwise illegal.",
                plant_social_media: `Peyote cactus has been used in ritual for 6000 years. Originating in what is now Mexico and Texas, the round, thornless cactus grows for 5 years before it's head or "button" is harvested. Like psilocybin, the mescaline in peyote causes hallucinations that can last 6-9 hours. In the United States, members of the Native Americam Church are permitted to harvest and consume peyote for ceremonial purposes.`,
                images: {
                    compounds: mescaline,
                    static: [pey_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/peyote/pey_ani_1.mp4", "https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/peyote/pey_ani_2.mp4"],
                }
            }
        }, 
        {
            shelf: {
                title: "Brugmanisia",
                cover: brug_cover
            },
            book: {
                sceintific_name: "Brugmansia arborea",
                common_name: "Brugmanisia",
                native_to: "From Ecuador to Peru, Bolivia and northern Chile",
                medicinal_parts: "leaves",
                big_sky: "Bright Night",
                botanical_characteristics: "With trumpet like flowers that hang downward, Brugmansia can grow up to 5 meters and is a perennial. It has a single, long calyx and gives off a sweet scent at night. tt produces berrylike fruit and brown seeds.",
                botanical_care: "Propagated from a cutting. It cannot handle frost and is mostly grown as an ornamental plant.",
                psychedelic_compounds: "Tropane alkaloids",
                part_for_consumption: 'Hot or cold water extraction is used on the leaves. Dried leaves can be used as an additive to Huachuma brew or smoked alone or with cannabis.',
                effects_of_consumption: "Brugmansia is a very potent member of the nighshade family and use should be taken seriously. Effects include dialation of the pupils and dryness of the mucus membranes, Psychoactive effects include intense hallucinations and potential coma and and respiratory paralysis resulting in death.",
                indigenous_roots: 'Priests in the Andean culture smoked dried leaves to induce prophecies. The crushed seeds were an ingredient in maize beer made by the Andean people.',
                traditional_and_medicinal_uses: "Used to treat tumors in Peru and perhaps as an anesthetic. Low potencies have been used in homeopathy.",
                legal_status_in_US: "Legal to grow. Mostly grown as an ornamental plant.",
                plant_social_media: `Brugmansia is a very potent nightshade that can cause serious harm for niave consumers. The trumpet shaped flowers are sweet smelling, and the dried leaves, when smoked or eaten, can cause intese hallicinations and risk of respiratory paralysis. Andean priests consumed bugmansia to experience the divine and induce prophesies. Now it is cultivated mostly as an ornamental plant.`,
                images: {
                    compounds: null,
                    static: [brug_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/brugmansia/brug_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Ice Plant",
                cover: ice_cover
            },
            book: {
                sceintific_name: "Delosperma cooperi",
                common_name: "Ice Plant",
                native_to: "South Africa",
                medicinal_parts: "leaves",
                big_sky: "Deep Forest",
                botanical_characteristics: 'Brilliant pink spiny flowers and long trailing greenery. The flowers often cover the entire plant earning it the name, "pink carpet".',
                botanical_care: "Propagated from cutting. It is hardy and although originally from Mediterrean climates, can be grown in the midwestern US.",
                psychedelic_compounds: "DMT & 5-MEO DMT",
                part_for_consumption: 'The leaves carry the psychoactive chemicals and can be made into a tincture.',
                effects_of_consumption: "Hallucinogenic properties associated with DMT including out of body experiences, time distortion, euphoria and shifts in perception.",
                indigenous_roots: 'Used in South Africa in the preparation of an alcoholic drink called khadi.',
                traditional_and_medicinal_uses: "No known medicinal properties.",
                legal_status_in_US: "Legal to grow. Commonly found wild in parts of the US.",
                plant_social_media: `Ice plants are very commonly spotted in places like California. However, very few people know that their leaves contain both DMT and 5-MEO DMT. Their spiny pink flowers cover the trails of green leaves. Although not a well known medicine, their hardiness and legal status make them a very accessible entheogen.`,
                images: {
                    compounds: meo,
                    static: [ice_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/iceplant/ice_ani_1.mp4"],
                }
            }
        },  
        {
            shelf: {
                title: "Passionflower",
                cover: pass_cover
            },
            book: {
                sceintific_name: "Passiflora (incarnata, involucrata and edulis)",
                common_name: "Passionflower",
                native_to: "Tropical rain forests of the Americas",
                medicinal_parts: "whole plant",
                big_sky: "Deep Forest",
                botanical_characteristics: 'One of the most unique flowers in the entheogen world, coming in three different styles and a variety of colors. The plant is a climbing green vine and many species bear edible fruit.',
                botanical_care: "Grown from seed in loose, airy potting soil, taking 2-6 weeks to germinate. Passionflower can be a good houseplant. ",
                psychedelic_compounds: "Apigenine and luteoline (whole incarnata plant), MAOI beta carbolines (roots of involucrata), carotenoids (fruit juice of edulis).",
                part_for_consumption: 'Incarnata varieties: entire plant is dried and made into tea, often mixed with valerian root and other medicinal herbs. The dried material can also be smoked. Involucrata varieties: roots are used to prepare an ayahuasca analog. Edulis varieties: fruit pulp is mixed with other ingredients to make jurema, an ayahuasca like drink.',
                effects_of_consumption: "Some varities produce a cannabis like high when smoked. The MAOI inhibitive properties aid the efficacy of DMT.",
                indigenous_roots: 'The roots from the involucrata varieties was used as an ayahuasca additive in the Iquitos region to intensify visions.',
                traditional_and_medicinal_uses: "Used primarily for sleep inducing/sedative properties in Amazonia and by Indians of the Caribbean and Central America. Incarnata varieties are a part of European folk medicine for the treatment of anxiety.",
                legal_status_in_US: "Legal to grow, mostly grown as an ornamental plant.",
                plant_social_media: `Passionflower is a stunning display of nature. A unique flower on top a green vine, different varieties of the plant are used for their chemical constituents.  The entire plant of the incarnata is dried and used as a medicine to promote sleep. The roots of the involucrata varities are used as an ayahusaca analog and the fruit of the edulis varieties is used to make an ayahuasca like drink. In the US, passionflower is mostly an ornamental houseplant.`,
                images: {
                    compounds: maoi,
                    static: [pass_stat_3],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/passion_flower/pass_ani_1.mp4"],
                }
            }
        },  
        {
            shelf: {
                title: "Chacruna",
                cover: chac_cover
            },
            book: {
                sceintific_name: "Psychotria viridis",
                common_name: "Chacruna",
                native_to: "Amazon lowlands",
                medicinal_parts: "leaves",
                big_sky: "Deep Forest",
                botanical_characteristics: 'Long, narrow shiny leaves with a woody stem. Small greenish white flowers that sprout from long stalks, The fruits are red berries and contain oval shaped seeds.',
                botanical_care: "Using cuttings is much easier than planting from seed as seeds can take 2 months to germinate. The cuttings root easily in humus rich soil.",
                psychedelic_compounds: "DMT",
                part_for_consumption: 'The leaves contain the DMT. The dried leaves are used in conjunction with the ayahusca vine to make ayahuasca brew.',
                effects_of_consumption: "When used with ayahuasca, it is said to bring heavenly people into consciousness. It also produces DMT related effects such as hallucinations, distorted sense of time, out of body experiences and shifts to perception.",
                indigenous_roots: 'The most common indigenous use was to combine chacruna with the ayahuasca vine to produce the ayahuasca brew. Shamans used this brew to travel to the "true reality" to receive answers and insight.',
                traditional_and_medicinal_uses: "The fresh leaves have been used to make a juice to treat migraines. However, the charcuna plant's therapeutic effects have been studied more in the context of the use of ayahuasca brew.",
                legal_status_in_US: "Legal to grow.",
                plant_social_media: `Chacruna is most known for making up the other half of ayahuasca brew and exhibiting the truth telling qualities of DMT. A glossy green plant with small delicate flowers, its leaves hold the DMT and are dried before being added to the ayahuasca brew. Leaves should be collected in early morning as that is when DMT levels in the plant are at their peak.`,
                images: {
                    compounds: dmt,
                    static: [chac_stat_2],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/chacruna/chac_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Blue Lotus",
                cover: blue_cover
            },
            book: {
                sceintific_name: "Nymphaea caerulea",
                common_name: "Blue Lotus",
                native_to: "The Nile delta ",
                medicinal_parts: "flowers",
                big_sky: "Infinite Other",
                botanical_characteristics: 'In the water lily family, blue lotus sit on top of a green pad on the water and have blue flowers. They are attached to a long stem that eminates from the pad.',
                botanical_care: "Propagation occurs by placing pieces of the root stalk in still water.",
                psychedelic_compounds: "Presumed to contain alkaloids, but no evidence of pharmaceutical properties.",
                part_for_consumption: 'The flowers are dried and made into tea.',
                effects_of_consumption: "As tea, it is said to produce hypotic effects. As a decoction, it is said to first cause nausea and tremors, but then creates a sense of calm with visual and auditory hallucinations that usually last 2 hours.",
                indigenous_roots: 'Used in ancient Egypt around the rituals of birth and death. Possibly used to create euphoric experience for those in the Egyptian elite priesthood.',
                traditional_and_medicinal_uses: "Traditionally, they were used to treat the liver, counteract poisons, and regulate urination.",
                legal_status_in_US: "Very rare plant, Legal to grow but unlikely to be available.",
                plant_social_media: `Revered by ancient Egyptians, the blue lotus is a rare and striking plant with a bright blue flower sitting on top of a long stem, perched on a green pad on the water. While it's exact constituents are not widely known, a blue lotus flower extraction can bring about visual and auditory hallucinations.`,
                images: {
                    compounds: null,
                    static: [blue_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/blue_lotus/blue_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Datura",
                cover: dat_cover
            },
            book: {
                sceintific_name: "Datura (discolor and innoxia)",
                common_name: "Datura",
                native_to: "American Southwest and Northern Mexico, although its exact origins are unknown",
                medicinal_parts: "seeds, leaves and stems",
                big_sky: "Bright Night",
                botanical_characteristics: 'Datura is an annual with dark green, slightly serrated leaves. The flowers point upwards and are white and shaped not unlike brugmansia. The flowers open at night and emit a sweet scent. The fruits are green with black seeds.',
                botanical_care: "Propagated from seed, datura has a short germinaton period of 5-10 days and can grow in soil when scattered on the ground. As datura grows, she becomes more finicky. Seedlings like to avoid both full sun and full shade, and do not like overwatering, or dry soil. However, once past the seedling stage, the plants become hardier.",
                psychedelic_compounds: "Tropane alkaloid Scopolamine",
                part_for_consumption: 'The seeds, leaves and stems are all used in preparation. Tea can be made from the dried leaves, or they can be smoked and the fresh root can be chewed.',
                effects_of_consumption: "The effects of datura are highly dose dependent and can range from medicinal to shamanic. Medium doses are said to have strong aphrodisiac effects, while shamanic doses produce intense hallucinations and visions. Like brugmansia, death through respiratory paralysis is possible.",
                indigenous_roots: 'The Seri Indians claim that discolor is one of the first plants in existence and warn against its consumption. In southern Mexico, shamans used innoxia to gain insight and see the future.',
                traditional_and_medicinal_uses: "The tribes of the Southwest used datura for a variety of medicinal purposes including to disinfect wounds, treat burns, relieve respiratory problems, treat snake and spider bites and as an anesthesia. It is also one of the most revered aphrodisiacs in Mexican folk medicine.",
                legal_status_in_US: "Legal to grow.",
                plant_social_media: `Believed to be one of the oldest plants in the universe, datura is a revered medicine and powerful shamanic tool. Doses can produce effects ranging from pain relief and wound treatment, to being a powerful aphrodisiac to producing shaman like visions of the future. The unassuming bushy plant with trumpet like flowers pointing upwards is one of the most powerful plants on earth.`,
                images: {
                    compounds: null,
                    static: [dat_stat_2],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/datura/dat_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Kanna",
                cover: kan_cover
            },
            book: {
                sceintific_name: "Sceletium tortuosum",
                common_name: "Kanna",
                native_to: "South Africa",
                medicinal_parts: "leaves",
                big_sky: "Infinite Other",
                botanical_characteristics: 'Succelent that has white flowers and thin green leaves along a long, thin stem that hangs down.',
                botanical_care: "Propagated from seed. Seedlings benefit from bright light, but avoid direct sun until the plants are moved outside. Kanna can be planted into a bigger container or into similar soil outdoors as long as there is no frost.",
                psychedelic_compounds: "Mesembrine, mesembrenone, mesembrenol and tortuosamine",
                part_for_consumption: 'The dried leaves can be chewed, made into tea or smoked.',
                effects_of_consumption: "Effects include mood elevation and reduction in anxiety. Large doses are euphoric but not necessarily hallucinogenic.",
                indigenous_roots: 'Used by South African hunter-gatherers for its mind altering properties.',
                traditional_and_medicinal_uses: "Kanna is known to fight depression, alleviate hunger and relieve pain.",
                legal_status_in_US: "Legal to grow.",
                plant_social_media: `Sceletium tortuosum, latin for Kanna, is native to South Africa and elevates the #floraverse.  The succelent has white flowers and thin green leaves that are said to alleviate hunger. Propogated from seed, the seedlings benefit from bright light but not direct sun until they mature.`,
                images: {
                    compounds: null,
                    static: [kan_stat_2],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/kanna/kan_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Kratom",
                cover: krat_cover
            },
            book: {
                sceintific_name: "Mitragyna speciosa",
                common_name: "Kratom",
                native_to: "Thailand",
                medicinal_parts: "leaves",
                big_sky: "Infinite Other",
                botanical_characteristics: 'With winged seeds and deep yellow flowers, the kratom tree can grow to be 12-16 meters, although most are 3-4 meters. The green leaves have a large surface area and its forked branches stretch upward.',
                botanical_care: "Can be propagated from seed or cutting, but usually grow best in marshy regions. Can be potted which will keep the size down. Propagation is difficult and few survive. Once a seedling has survived, kratom is a hardy plant.",
                psychedelic_compounds: "Indole alkaloids",
                part_for_consumption: 'Dried leaves are smoked, chewed or made into an extract. They can also be made into a powder and used for tea. Fresh leaves can also be chewed.',
                effects_of_consumption: "Effects of kratom are a paradox. They include both stimulation and excitability, and sedation and relaxation.",
                indigenous_roots: 'In Thailand, kratom was traditionally used to help offset opium addiction by providing a substitute.',
                traditional_and_medicinal_uses: "Used to treat diarrhea in Thai medicine as well as a substitute for amphetamines.",
                legal_status_in_US: "Legal to grow in AK, AZ, CA, DC, ID, MA, MT, NV, NM, NJ, NY, OR, SC, UT, VT, VA, WA",
                plant_social_media: `Historically known as an effective substitute for opium and methamphetamine, kratom is a bit of a paradox. A tree with deep yellow flowers that grows well in marshy conditions, it produces both a stimulating and calming effect. Native to Thailand, kratom is used in the US as a harm reduction tool and caffeine substitute.`,
                images: {
                    compounds: null,
                    static: [krat_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/kratom/krat_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Morning Glory",
                cover: mor_cover
            },
            book: {
                sceintific_name: "Ipomoea violacea",
                common_name: "Morning Glory",
                native_to: "Tropical regions of Mexico",
                medicinal_parts: "seeds",
                big_sky: "Deep Forest",
                botanical_characteristics: 'Morning glory is a vine that flowers all year in its native habitat. Flowers open during the day and close at night, and are usually shades of violet but can also be pink, white or blue. Seeds are black and triangular. ',
                botanical_care: "Grown from seed, morning glorys germinate in 10-20 days. They require a good deal of water, but can also tolerate dryness. They do well in alkaline soil in places such as balconies and window boxes.",
                psychedelic_compounds: "Ergot and indole alkaloids",
                part_for_consumption: 'The psychoactibe material is in the seeds, which can be groind and added to liquid.',
                effects_of_consumption: 'Eating seeds can cause nausea and vomiting. Exctraction via cold water is said to be the best method of ingestion. Hallucinogenic effects include seeing "small people", as well as hypnotic effects.',
                indigenous_roots: 'The Aztecs were likely the first to enagage in ceremonial use with morning glorys.',
                traditional_and_medicinal_uses: "No known medicinal properties.",
                legal_status_in_US: "Legal to grow, usually as an ornamental plant.",
                plant_social_media: `Morning Glory plants have psychedelic seeds. Native to Mexico, it is now often found as an ornamental house plant in the US. It's vibrant flowers bear seeds that can cause hallucinations including visits by "little people". Although they have no documented medical use, they have been an important spiritual tool for many Mexican tribes.`,
                images: {
                    compounds: null,
                    static: [mor_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/morning_glory/mor_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Nicotania",
                cover: nic_cover
            },
            book: {
                sceintific_name: "Nicotania rustica",
                common_name: "Nicotania",
                native_to: "Either Mexico or northern South America",
                medicinal_parts: "leaves",
                big_sky: "Infinite Other",
                botanical_characteristics: 'Nicotania is an annual bearing yellow flowers with small brown seeds incased in a round capsuled fruit.',
                botanical_care: "Propagated from seed, nicotania likes normal soil and can be planted by sprinking the seeds over the planting area. ",
                psychedelic_compounds: "Mapacho, rustic tobacco, acetylchlorinergic compounds",
                part_for_consumption: 'Leaves are sun dried, powdered and mixed with other substances like lime.Leaves can also be mixed with other plants or made into snuff. ',
                effects_of_consumption: 'Can produce hallucinations that include dreamlike states, heightened perceptions, visions of light, spontaneous insights and the feeling of tunnelvision.',
                indigenous_roots: 'Used by the Aztects similarly to peyote, to gain spiritual insight. Many Mexican tribes used nicotania as part of their ritual in a shamanic manner, to incite visions.',
                traditional_and_medicinal_uses: "As a topical, nicotania has been used to help swollen and sore muscles. The Aztects used nicotania to treat a range of condtions, from asthma to snake bites. Today in Mexico, nicotania is mixed with ephedra to treat headaches.",
                legal_status_in_US: "Legal to grow.",
                plant_social_media: `Much stronger than the tobacco variety of nicotania, the rustica variety can bring on strong hallucinations. The yellow flowered plant has deep roots in Mexican tribal culture and has been used by many groups in ceremony to bring on shamanic visions. Connected to gods and goddesses, nicotania has a deep spiritual history of reverence.`,
                images: {
                    compounds: nicotine,
                    static: [nic_stat_2],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/nicotiana/nic_ani_1.mp4"],
                }
            }
        },
        {
            shelf: {
                title: "Crested Huachuma",
                cover: hua_cover
            },
            book: {
                sceintific_name: "Trichocereus pachanoi Cristata",
                common_name: "Crested Huachuma",
                native_to: "Peru at elevations between 2000-3000 meters",
                medicinal_parts: "cactus",
                big_sky: "Big Sky",
                botanical_characteristics: 'Tall and nearly spinless, it can grow up to 6 meters. It has white flowers which only bloom at night and can have anywhere from 0-12 ribs.',
                botanical_care: "Can be propagated from seeds or cuttings. It loves the climate in California and grows quickly when watered regularly, but can also survive well without water. It can also be grafted onto other cacti.",
                psychedelic_compounds: "Mescaline",
                part_for_consumption: 'Slices of the fresh cactus and also the rind, ground up into a powder.',
                effects_of_consumption: 'When consumed alone, effects vary based on dose. Higher doses can yield psychedelic effects, more on the emotional than sensory level. The plant is often mixed with other entheogens like Brugmansia and Datura to intensify the psychoactive effect.',
                indigenous_roots: "Shamans in early Andean civilization have used the cactus for at least 2000 years. ",
                traditional_and_medicinal_uses: "Played a role in shamanic rituals for the shaman to consume to know what was wrong with the people they were healing.",
                legal_status_in_US: "Legal to grow the plant, but not harvest the mescaline.",
                plant_social_media: `Meet Huachuma, a hardy, near spineless #cactus that loves light and warmth and can grow to be 6 meters. This night bloomer has a cacti stem that divides outward giving it its unique crested shape. Originating in Peru, he is used as a shamanic tool to help humans heal.`,
                images: {
                    compounds: mescaline,
                    static: [hua_stat_1],
                    interactive: ["https://SACRD-LIBRARY.s3.us-west-004.backblazeb2.com/huachuma/hua_ani_1.mp4"],
                }
            }
        }
    ]

    useEffect(()=>{
        window.addEventListener("scroll", ()=>{
            if (window.scrollY >= 175){
                console.log(window.scrollY)
                setCatalog("show")
                setStatlog("closed")
            } else {
                setCatalog("hide")
            }
        })
    },[catalog])
    return(
        <div className="row">  
            <div className="col-12 d-flex flex-column" 
            style={{ backgroundColor: "#FFC4E8"}}
            >       
                <div className="catalog d-flex flex-row" style={statlog === "closed" ? {overflowX: "scroll", overflowY: "hidden", height: "0rem", transition: "all 0.25s ease"} : {overflowX: "scroll",  overflowY: "hidden", height: "20rem", transition: "all 0.25s ease"}}>
                    {
                        data.map((plant)=>{
                            return(
                                <button className="btn" key={data.indexOf(plant)} onClick={()=>{
                                    setIndx(data.indexOf(plant))
                                }} style={{height: "20rem", width: "fit-content"}}>
                                    <div className="card" style={{width:"200px", background: "none", border: "none"}}>
                                        <img className="card-img-top" src={plant.shelf.cover} alt="option visual"/>
                                        <div className="card-body d-flex justify-content-center">
                                            <div className="text-center p-1 rounded" style={{backgroundColor: "#fff5b5", boxShadow: "2px 2px 2px grey"}}>
                                                <span className="Flora-Font Sacrd-Grn text-uppercase">{plant.shelf.title}</span>  
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            )
                        })
                    }
                </div>
                <button className="Sacrd-Grn Flora-Font pt-2" style={{background: "none", border: "none"}} onClick={(e)=>{
                    if(statlog === "open"){
                        setStatlog("closed")
                        e.currentTarget.innerHTML = `<span class="text-uppercase mx-1">view library</span> <i class="fa-solid fa-chevron-down"/>`
                    } 
                    else{
                        setStatlog("open")
                        e.currentTarget.innerHTML = `<span class="text-uppercase mx-1">close library</span> <i class="fa-solid fa-chevron-up"/>`
                    }
                }}>
                    <span className="text-uppercase mx-1">View Library</span>
                    <i className="fa-solid fa-chevron-down"/>
                </button>
            </div>
            <div className="col-12 d-flex flex-column" 
            style={catalog === "hide"? {backgroundColor: "#FFC4E8", position: "fixed", zIndex: 98, top: "-90px", transition: "all ease 0.5s"}:{backgroundColor: "#FFC4E8", position: "fixed", zIndex: 99, top: "45px", transition: "all ease 0.5s"}}
            >       
                <div className="catalog d-flex flex-row" style={{overflowX: "scroll",  overflowY: "hidden", height: "0px", transition: "all 0.25s ease"}}>
                    {
                        data.map((plant)=>{
                            return(
                                <button className="btn" key={data.indexOf(plant)} onClick={()=>{
                                    setIndx(data.indexOf(plant))
                                }} style={{height: "20rem", width: "fit-content"}}>
                                    <div className="card" style={{width:"200px", background: "none", border: "none"}}>
                                        <img className="card-img-top" src={plant.shelf.cover} alt="option visual"/>
                                        <div className="card-body d-flex justify-content-center">
                                            <div className="text-center p-1 rounded" style={{backgroundColor: "#fff5b5", boxShadow: "2px 2px 2px grey"}}>
                                                <span className="Flora-Font Sacrd-Grn text-uppercase">{plant.shelf.title}</span>  
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            )
                        })
                    }
                </div>
                <button className="Sacrd-Grn Flora-Font pt-2" value="closed" style={{position: "relative", zIndex: 98, background: "none", border: "none"}} onClick={(e)=>{
                    const catalog = e.currentTarget.parentNode.children[0]
                    if(e.currentTarget.value === "open"){
                        catalog.style = "overflow-x: scroll; overflow-y: hidden; height: 0rem; transition: all 0.5s ease;"
                        e.currentTarget.value = "closed"
                        e.currentTarget.innerHTML = `<span class="text-uppercase mx-1">View Library</span> <i class="fa-solid fa-chevron-down"/>`
                    } 
                    else{
                        catalog.style = "overflow-x: scroll; overflow-y: hidden; height: 20rem; transition: all 0.5s ease;"
                        e.currentTarget.value = "open"
                        e.currentTarget.innerHTML = `<span class="text-uppercase mx-1">Close Library</span> <i class="fa-solid fa-chevron-up"/>`
                    }
                }}>
                    <span className="text-uppercase mx-1">View Library</span>
                    <i className="fa-solid fa-chevron-down"/>
                </button>
            </div>
            <div className="col-12 pt-3">
                <div className="d-flex align-items-center justify-content-center p-1">
                    <span className="Sacrd-Grn Flora-Font text-uppercase" style={{fontSize: "calc(1.25rem + 1vw)"}}>Botanical Library of Psychedelic Plants</span>
                </div>
                <div className="p-2 w-100" style={{backgroundColor: "#E8FFFB"}}>
                    <div className="p-1 text-center rounded" style={{backgroundColor: "#04f2af"}}>
                        <span className="Sacrd-Grn Flora-Font fs-3 text-uppercase" style={{backgroundColor: "#04f2af"}}>{data[indx].book.common_name}</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
            {
                data[indx].book.images.interactive.map((ani)=>{
                    return(
                        <div key={data[indx].book.images.interactive.indexOf(ani)} style={{position: "relative"}}>
                            <video id="video" className="img-fluid rounded mb-2" style={{border: "none"}} src={ani} muted={true} autoPlay={true} loop playsInline/>
                            <input type="range" defaultValue="0" min="0" onChange={(e)=>{
                                const video = e.currentTarget.parentNode.children[0],
                                input = e.currentTarget;
                                // setTimeout(()=>{
                                //     video.play()
                                // }, 3000);
                                video.pause()
                                input.max = video.duration
                                video.currentTime = e.currentTarget.value
                            }} style={{position: "absolute", height: "500px", left: 0}}/>
                        </div>
                    )
                })
            }
            {
                data[indx].book.images.static.map((stat)=>{
                    return(
                        <img key={data[indx].book.images.static.indexOf(stat)} className="img-fluid rounded my-2" style={{border: "none", width: "500px"}} src={stat} alt="plant visual"/>
                    )
                })
            }
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
                <div className="d-flex flex-row flex-wrap align-items-center Flora-Font Sacrd-Grn fs-5 text-capitalize">
                    <span>scientific name:</span>
                    <span className="mx-1">{data[indx].book.sceintific_name}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">native to:</span>
                    <span className="mx-1">{data[indx].book.native_to}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">medicinal parts:</span>
                    <span className="mx-1">{data[indx].book.medicinal_parts}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">Sacred Plants of the:</span>
                    <span className="mx-1">{data[indx].book.big_sky}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">botanical characteristics:</span>
                    <span className="mx-1">{data[indx].book.botanical_characteristics}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">botanical care:</span>
                    <span className="mx-1">{data[indx].book.botanical_care}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">Psychoactive Compounds:</span>
                    <span className="mx-1">{data[indx].book.psychedelic_compounds}</span>
                </div>
                {
                    data[indx].book.images.compounds === null ?
                    null
                    :
                    <img className="rounded" style={{width: "150px"}} src={data[indx].book.images.compounds} alt="psychedelic chemical compound visual"/>
                }
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">part for consumption:</span>
                    <span className="mx-1">{data[indx].book.part_for_consumption}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">effects of consumption:</span>
                    <span className="mx-1">{data[indx].book.effects_of_consumption}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">indigenous roots:</span>
                    <span className="mx-1">{data[indx].book.indigenous_roots}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">traditional and medicinal uses:</span>
                    <span className="mx-1">{data[indx].book.traditional_and_medicinal_uses}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">legal status in US:</span>
                    <span className="mx-1">{data[indx].book.legal_status_in_US}</span>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <span className="Flora-Font text-capitalize">plant social media:</span>
                    <span className="mx-1">{data[indx].book.plant_social_media}</span>
                </div>
            </div>
        </div>
    )
}

export default Body