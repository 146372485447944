import React from 'react';
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

// pages
import Landing from "./pages/landing/landing"

// pagination
import Faqs from './pages/faqs/faqs'
import Team from './pages/team/team'
// import Partners from './pages/partners/partners'
import Terms from './pages/terms/terms'
import Botanical from './pages/botanical_library/botanical';

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Landing/>,
    },
    {
      path: "/faqs",
      element: <Faqs/>,
    },
    {
      path: "/team",
      element: <Team/>,
    },
    {
      path: "/terms",
      element: <Terms/>,
    },
    // {
    //   path: '/partners',
    //   element: <Partners/>
    // },
    {
      path: '/library',
      element: <Botanical/>
    }
  ]
);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );