import React from 'react';

import tour from "../../../images/landing/tour.png"
import community from "../../../images/landing/community.png"
import shop from "../../../images/landing/shop.png"
import BRUGMANSIA from "../../../images/landing/BRUGMANSIA.gif"

const Body = () => {

    return(
        <div className="row py-1 d-flex justify-content-center">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-between align-items-center">
                <a href="https://opensea.io/collection/deltaflora"><img className="rounded my-1 shadow img-fluid" src={community} alt="something"/></a>
                <a href="https://my.matterport.com/show/?m=mvHce8tduYB&brand=0&play=1&wh=0"><img className="rounded my-1 shadow img-fluid" src={tour} alt="something"/></a>
                <a href="https://shop.sacrdgardn.com/"><img className="rounded my-1 shadow img-fluid" src={shop} alt="something"/></a>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column align-items-center">
                <div className="d-flex flex-column" style={{width: "fit-content"}}>
                    <img  className="img-fluid my-1 rounded shadow-sm" src={BRUGMANSIA} alt="something"/>
                    <a className="text-uppercase Sacrd-Grn Flora-Font rounded fs-3 mt-2 mb-1 shadow py-2 w-100 text-center text-decoration-none" style={{backgroundColor: "#04F2AF", border: "none"}} href="/library">botanical library</a>
                </div>
            </div>
            <div className="col-12 col-lg-10 d-flex flex-column text-center justify-content-center py-4">
                <p className="Sacrd-Grn Flora-Font fs-3">Sacred Garden is a real and virtual psychedelic plant community with a focus on conservation, cultivation and education. ΔFLORA is your membership token.</p>
            </div>
        </div>
    )
};

export default Body;