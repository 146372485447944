import React from "react";

const Footer = () => {
    
    return(
        <div className="row d-flex flex-column align-items-center">
            <div className="col-12 col-sm-8 col-xl-5 d-flex flex-column pt-4">
                <span className="Flora-Font form-signup fs-2 text-center">
                    Sign up for news and updates
                </span>
                <form className="d-flex flex-row justify-content-center" action="https://app.convertkit.com/forms/3038011/subscriptions" 
                method="post" data-sv-form="3038011" data-uid="1b6e2bd4ea" data-format="inline" data-version="5" data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}">
                    <div className="d-flex flex-grow-1">
                        <input type="email" className="form-control Flora-Font fs-3 m-1" id="exampleInputEmail1" aria-describedby="emailHelp" name="email_address" aria-label="Email Address" placeholder="Email Address" required=""/>
                    </div>
                    <button type="submit" data-element="submit" className="btn form-btn Flora-Font fs-3 m-1">Submit</button>
                </form>
                <div className="d-flex flex-row">
                    <a href="https://discord.gg/jbv7raNcu2" className="text-decoration-none text-center m-1 p-2 fs-1 rounded flex-fill" style={{backgroundColor: "#5865F2", color: "white"}}>
                        <i className="fa-brands fa-discord"></i>
                    </a>
                    <a href="https://www.instagram.com/sacrdgardn/" className="text-decoration-none text-center m-1 p-2 fs-1 rounded flex-fill" style={{backgroundColor: "#3f729b", color: "white"}}>
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="https://twitter.com/sacrdgardn" className="text-decoration-none text-center m-1 p-2 fs-1 rounded flex-fill" style={{backgroundColor: "#00acee", color: "white"}}>
                        <i className="fa-brands fa-twitter"></i>                        
                    </a>     
                    <a href="https://opensea.io/SACRDGARDN" className="text-decoration-none text-center m-1 p-2 fs-1 rounded flex-fill" style={{backgroundColor: "#2081E2", color: "white"}}>
                        <i className="fa-solid fa-sailboat"></i>
                    </a> 
                </div>
                <div className="d-flex flex-column Flora-Font text-center">
                    <a href="/terms" className="m-1 fs-5">Terms and Conditions</a>
                    <div className="fs-5 Sacrd-Grn">© GARDN LLC</div>
                </div>
            </div>
        </div>  
    )
}

export default Footer