import React from "react";

// import amanda from "../../../images/team/amanda.png"
import eli from "../../../images/team/eli.jpg"
import jenna from "../../../images/team/jenna.jpg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'

const Body = () => {

    const team = [
        // {
        //     img: amanda,
        //     name: "Amanda Reiman, PhD MSW",
        //     title: "Founder & Head of Community",
        //     bio: "Amanda Reiman is a social ethnobotanist and the Founder of Personal Plants, a platform designed to deepen the relationship between people and flora. Dr. Reiman is an internationally recognized cannabis expert and public health researcher. Dubbed “The Brain” by Elle.com, she is a leader in the field of cannabis as a substitute for alcohol and other substances and has presented her research on cannabis dispensaries and the use of cannabis as a substitute for opiates all over the world. She taught courses on substance abuse treatment and drug policy at UC Berkeley for over 10 years. She has written for/been quoted in The Huffington Post, New York Times, Washington Post, Forbes, Rolling Stone and Playboy as well as numerous peer reviewed academic journals and several textbooks. When she isn't working, you can find her in the garden, working out in the metaverse, consuming cannabis and chocolate and being a kickass dog/cat mom."
        // },
        {
            img: eli,
            name: "Eli Robbins",
            title: "Founder & Head of Design",
            bio: "Eli Robbins is an award winning Experience Designer with his practice CIVIO Studio. His work includes a wide variety of experiences both physical and digital with a strong background in Architecture, UX Design, and Immersive/Interactive Environments. He has experience designing bespoke virtual environments, metaverse spaces including plots in Cryptovoxels, and real physical environments with media architecture and digital interaction. He is well versed in NFT's, Brand Strategy, AR/VR, 3D Scanning, Photogrammetry, 3D modeling, Rendering/Visualization, Graphic Design, and Motion Graphics. When he isn't working, Eli likes to tend to his house plants, make linocut art, shoot film photography, flyfish, make delicious food and chill with his son Emilio and partner Rachel."
        },
        {
            img: jenna,
            name: "Jenna Greenfield",
            title: "Founder & Head of Business",
            bio: "Jenna Greenfield is a CPA, entrepreneur and early stage enthusiast. She has spent the last decade in Silicon Valley working with tech and cannabis companies to help scale, monetize and build a successful business for the long term. Her experience spans business development, operations and finance. She built Sagely, her own consulting and beta fintech startup for the cannabis industry in 2016 which was acquired in 2020. Since then, she has become fully immersed in NFTs, DAOs and the future of Web3. When she isn’t working, you can find her traveling, kite surfing, enjoying the great outdoors, advocating for her pitbull and cooking local produce from her CSA!"
        }
    ]

    return(
        <div className='row d-flex flex-row flex-wrap justify-content-evenly g-1'>
            {
                team.map((mem)=>{
                    return(
                        <div className="col-12 col-md-6" key={team.indexOf(mem)}>
                            <div className="rounded d-flex flex-column justify-content-between p-2" style={{backgroundColor: "#FFF5B5", border: "solid 2px #FFE0E0"}}>
                                <div className="d-flex flex-column" >
                                    <div className="d-flex flex-row"> 
                                        <img src={mem.img} className="img-fluid rounded" alt={`${mem.name.split(" ")[0]}`} style={{height: "200px", width: "200px"}}/>
                                        <span className="align-self-end Flora-Font Sacrd-Grn text-uppercase mx-1 fs-3">
                                            {mem.name}
                                        </span>  
                                    </div>
                                                                  
                                    <div className="p-1 w-100 rounded" style={{backgroundColor: "#FFE0E0"}}></div>
                                </div>
                                <div className='d-flex flex-row my-1'>
                                    <span className="fs-5 Flora-Font text-muted">{mem.title}</span>
                                    <a href="https://twitter.com/AmandaReiman" className="fs-5 ms-auto">
                                    <FontAwesomeIcon icon={faTwitter}/>
                                    </a>
                                </div>
                                <div className='d-flex flex-row align-items-center justify-content-center p-2 rounded my-1' style={{backgroundColor: '#04F2AF'}}>
                                    <span className='fs-5 Flora-Font Sacrd-Grn'>BIO</span>
                                    <button className="btn ms-auto fs-5 Sacrd-Grn" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${mem.name.split(" ")[0]}`} aria-expanded="false" aria-controls={`collapse${mem.name.split(" ")[0]}`} style={{color: '#00544B'}}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                    </button>
                                </div>
                                <div className="collapse p-1" id={`collapse${mem.name.split(" ")[0]}`}>
                                    <p className="Flora-Font Sacrd-Grn card card-body">{mem.bio}</p>
                                </div>
                            </div>
                        </div>   
                    )
                })
            }     
        </div>
    )
} 

export default Body